<template>
  <div class="container">
    <h2 class="headline">
      Why use our Beta version?
    </h2>
    <div
      :style="{
        marginTop: '1rem',
        maxWidth: '65ch',
      }"
    >
      <p>
        We no longer support this version of the business app.
      </p>

      <p>
        Our Beta version is constantly updated with the latest features and
        improvements. By using it, you can stay ahead of the curve and provide
        valuable feedback to help us shape the future of our app.
      </p>
    </div>

    <v-btn
      color="primary"
      href="https://beta.makeinfluence.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Try Beta
    </v-btn>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
